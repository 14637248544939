<template>
  <div class="upload-jst">
    <div>
      <el-form>
      <el-form-item label="请选择Excel文件">
        <el-upload
          ref="upload"
          :auto-upload="true"
          :multiple="false"
          :limit="1"
          :on-exceed="fileUploadExceed"
          :on-success="fileUploadSuccess"
          :on-error="fileUploadError"
          :action="BASE_API + '/auth/logistics/import/km'"
          name="file"
          accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        >
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
      </el-form-item>
    </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="onCancel">
          取消
        </el-button>
      </div>
  </div>
  </div>
</template>

<script>
import {getBaseURL} from '@/utils/someCommen'
export default {
  name: 'UploadKm',
  props: {
  },
  created () {

  },
  data () {
    return {
      BASE_API: getBaseURL('/auth/logistics/import/km'), //获取后端接口地址
    }
  },
  methods: {
    // 上传多于一个文件时
    fileUploadExceed() {
      this.$message.warning('只能选取一个文件')
    },

    //上传成功回调
    fileUploadSuccess(response) {
      if (response.code === 200) {
        this.$message.success(response.message)
        this.$refs.upload.clearFiles();
        this.$emit('success')
      } else {
        this.$refs.upload.clearFiles();
        this.$message.error(response.message)
      }
    },

    //上传失败回调 updateLogisticsStatus
    fileUploadError(error) {
      this.$message.error('数据导入失败')
    },

    onCancel () {
      this.$refs.upload.clearFiles();
      //设置取消状态，让父组件处理
      this.$emit('cancel')
    },

  }
}
</script>

<style lang="scss" scoped>
.dialog-footer {
  display: flex;
  //设置内部元素水平居中
  justify-content: center;
  //设置内部元素垂直居中
  align-items: center;
}
</style>
