<template>
  <div class="upload-one">
    <div>
      <div>
        <el-form  ref="form" :model="form" :rules="rules" label-position="left" label-width="150px" size="small">
          <el-form-item label="订单编号：" prop="orderNo">
            <el-input v-model="form.orderNo"></el-input>
          </el-form-item>
          <el-form-item label="店铺名称：" prop="shopName">
            <el-input v-model="form.shopName"></el-input>
          </el-form-item>
          <el-form-item label="快递单号：" prop="mailNo">
            <el-input v-model="form.mailNo"></el-input>
          </el-form-item>
          <el-form-item label="主快递单号：" prop="mainMailNo">
            <el-input v-model="form.mainMailNo"></el-input>
          </el-form-item>
          <el-form-item label="打单人手机号：" prop="printPhone">
            <el-input v-model="form.printPhone"></el-input>
          </el-form-item>
          <el-form-item label="打单人：" prop="printName">
            <el-input v-model="form.printName"></el-input>
          </el-form-item>
          <el-form-item label="打单时间：" prop="printStrTime">
            <el-date-picker
              v-model="form.printStrTime"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="发件人：" prop="sender">
            <el-input v-model="form.sender"></el-input>
          </el-form-item>
          <el-form-item label="发件人手机号：" prop="senderPhone">
            <el-input v-model="form.senderPhone"></el-input>
          </el-form-item>
          <el-form-item label="收件人省级：" prop="recipientProvince">
            <el-input v-model="form.recipientProvince"></el-input>
          </el-form-item>
          <el-form-item label="收件人地级：" prop="recipientCity">
            <el-input v-model="form.recipientCity"></el-input>
          </el-form-item>
          <el-form-item label="收件人县级：" prop="recipientArea">
            <el-input v-model="form.recipientArea"></el-input>
          </el-form-item>
          <el-form-item label="平台类型：" prop="platformType">
            <el-select v-model="form.platformType" clearable>
              <el-option v-for="item in platformTypeList" :key="item.status" :label="item.statusDesc"
                         :value="item.status"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="部门归属：" prop="deptSortOut">
            <el-select v-model="form.deptSortOut" clearable>
              <el-option v-for="item in deptSortOutList" :key="item.status" :label="item.statusDesc"
                         :value="item.status"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="快递备注：" prop="mark">
            <el-input type="textarea" placeholder="请输入备注，不超过200字" v-model="form.mark"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetForm('form')">重置</el-button>
        <el-button type="primary" @click="onSubmit">添加</el-button>
      </div>
  </div>
  </div>
</template>

<script>
import {uploadNo} from "@/services/logistics";
import {findAllPlatformType,findAllDeptSortOut} from '@/services/status'
export default {
  name: 'UploadOne',
  props: {
  },
  created () {
    this.loadDeptSortOut()
    this.loadPlatformType()
  },
  data () {
    return {
      deptSortOutList: [],
      platformTypeList: [],
      form: {},
      // 用于设置表单校验规则
      rules: {
        orderNo: [{required: true,trigger: 'blur',message: '订单编号不能为空'}],
        shopName: [{required: true,trigger: 'blur',message: '店铺名称不能为空'}],
        mailNo: [{required: true,trigger: 'blur',message: '快递单号不能为空'}],
        mainMailNo: [{required: true,trigger: 'blur',message: '主快递单号不能为空'}],
        printPhone: [{required: true,trigger: 'blur',message: '打单人手机号不能为空'}],
        printName: [{required: true,trigger: 'blur',message: '打单人不能为空'}],
        printStrTime: [{required: true,trigger: 'blur',message: '打单时间不能为空'}],
        sender: [{required: true,trigger: 'blur',message: '发件人不能为空'}],
        senderPhone: [{required: true,trigger: 'blur',message: '发件人手机号不能为空'}],
        recipientProvince: [{required: true,trigger: 'blur',message: '收件人省级不能为空'}],
        recipientCity: [{required: true,trigger: 'blur',message: '收件人地级不能为空'}],
        recipientArea: [{required: true,trigger: 'blur',message: '收件人县级不能为空'}],
        platformType: [{required: true,trigger: 'blur',message: '平台类型不能为空'}],
        deptSortOut: [{required: true,trigger: 'blur',message: '部门归属不能为空'}],
        mark: [{required: true,trigger: 'blur',message: '快递备注不能为空'}]
      },
    }
  },
  methods: {
    async loadPlatformType() {
      //查询所属平台列表
      const {data} = await findAllPlatformType()
      if (200 === data.code) {
        this.platformTypeList = data.data
      } else {
        this.$message.error('查询所属平台列表失败')
      }
    },
    async loadDeptSortOut() {
      //查询部门归属列表
      const {data} = await findAllDeptSortOut()
      if (200 === data.code) {
        this.deptSortOutList = data.data
      } else {
        this.$message.error('查询部门归属列表失败')
      }
    },
    async onSubmit () {
      try {
        // 设置校验
        await this.$refs.form.validate()
        // 手动上传单号
        const { data } = await uploadNo(this.form)
        if (200 === data.code) {
          this.$emit('success')
          this.$message.success('操作成功')
          this.resetForm('form')
        } else {
          this.$message.error(data.message)
        }
      } catch (err) {
        // 设置校验失败后的功能提示
        console.log(err)
      }
    },

    resetForm(formName) {
      // 重置表单
      this.$refs[formName].resetFields();
    },
  }
}
</script>

<style lang="scss" scoped>
.dialog-footer {
  display: flex;
  //设置内部元素水平居中
  justify-content: center;
  //设置内部元素垂直居中
  align-items: center;
}
</style>
