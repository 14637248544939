<template>
  <div class="uploadno">
    <el-card>

      <div slot="header" class="clearfix">

        <div style="margin-bottom:20px;">
          快递单号上传
        </div>
        <div style="margin-bottom: 20px;">
          <el-button
            @click="dialogKdzs = true"
            type="primary"
            size="mini"
            icon="el-icon-upload2"
          >
            批量上传快递助手单号
          </el-button>

          <el-button
            @click="dialogWoda = true"
            type="danger"
            size="mini"
            icon="el-icon-upload2"
          >
            批量上传我打单号
          </el-button>
          <el-button
            @click="dialogJst = true"
            type="success"
            size="mini"
            icon="el-icon-upload2"
          >
            批量上传聚水潭单号
          </el-button>

          <el-button
            @click="dialogKm= true"
            type="warning"
            size="mini"
            icon="el-icon-upload2"
          >
            批量上传快麦单号
          </el-button>

          <el-button
            @click="dialogOne = true"
            type="info"
            size="mini"
            icon="el-icon-upload2"
          >
            手动上传快递单号
          </el-button>

<!--          <el-button-->
<!--            @click="exportDataYunda"-->
<!--            type="warning"-->
<!--            size="mini"-->
<!--            icon="el-icon-download"-->
<!--          >-->
<!--            今日韵达数据-->
<!--          </el-button>-->

        </div>
        <el-form :inline="true" ref="form" :model="form" class="demo-form-inline" @submit.native.prevent size="mini">
          <el-form-item label="上传时间：">
            <el-date-picker
              v-model="form.uploadDateQuery"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              format='yyyy-MM-dd HH:mm:ss' value-format='yyyy-MM-dd HH:mm:ss'>
            </el-date-picker>
          </el-form-item>
          <el-form-item class="btn-right">
            <el-button type="primary" @click="onSubmit" :disabled="isLoading">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-table
          :data="tableData"
          border
          v-loading="isLoading"
          style="width: 100%" size="mini">
          <el-table-column type="index" label="编号" width="80"></el-table-column>
          <el-table-column prop="fileName" label="文件"></el-table-column>
          <el-table-column prop="typeDesc" label="上传方式"></el-table-column>
          <el-table-column label="上传时间">
            <template slot-scope="scope">
              <span>{{ scope.row.uploadTime | dateFormate }}</span>
            </template>
          </el-table-column>
          <el-table-column label="完成时间">
            <template slot-scope="scope">
              <span>{{ scope.row.finishedTime | dateFormate }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态">
            <template slot-scope="scope">
              <span v-if="scope.row.status === '1'">上传成功</span>
              <span v-else>上传失败</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--      分页组件结构-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.currentPage"
        :page-sizes="[10,50, 200, 500, 1000]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="dataTotal"
        :disabled="isLoading"
      >
      </el-pagination>

    </el-card>

    <el-dialog title="快递助手单号导入" :visible.sync="dialogKdzs" width="30%" :before-close="kdzsCancelUpload">
      <upload-kdzs @cancel="kdzsCancelUpload" @success="kdzsSuccessUpload"></upload-kdzs>
    </el-dialog>

    <el-dialog title="我打单号导入" :visible.sync="dialogWoda" width="30%" :before-close="wodaCancelUpload">
      <upload-woda @cancel="wodaCancelUpload" @success="wodaSuccessUpload"></upload-woda>
    </el-dialog>

    <el-dialog title="聚水潭单号导入" :visible.sync="dialogJst" width="30%" :before-close="jstCancelUpload">
      <upload-jst @cancel="jstCancelUpload" @success="jstSuccessUpload"></upload-jst>
    </el-dialog>

    <el-dialog title="快麦单号导入" :visible.sync="dialogKm" width="30%" :before-close="kmCancelUpload">
      <upload-km @cancel="kmCancelUpload" @success="kmSuccessUpload"></upload-km>
    </el-dialog>

    <el-dialog title="手动上传单号" :visible.sync="dialogOne" width="30%" :before-close="oneCancelUpload">
      <upload-one @cancel="oneCancelUpload" @success="oneSuccessUpload"></upload-one>
    </el-dialog>

  </div>
</template>

<script>
import {getBaseURL} from '@/utils/someCommen'
import {findAllUploadFileByPage} from '@/services/logistics'
import { dateFormate} from '@/utils/dateUtil'
import UploadKdzs from "./components/UploadKdzs";
import UploadWoda from "./components/UploadWoda";
import UploadJst from "./components/UploadJst";
import UploadKm from "./components/UploadKm";
import UploadOne from "./components/UploadOne";
export default {
  components:{UploadKdzs,UploadWoda,UploadJst,UploadKm,UploadOne},
  name: 'uploadno',
  data() {
    return {
      dialogKdzs: false, //快递助手对话框是否显示
      dialogWoda: false, //我打对话框是否显示
      dialogJst: false, //聚水潭对话框是否显示
      dialogKm: false, //快麦对话框是否显示
      dialogOne: false, //手动上传对话框是否显示
      userInfo: {},
      mailNo: '',
      // 用于存储资源列表数据
      tableData: [],
      form: {
        // 当前显示页号
        currentPage: 1,
        pageSize: 10
      },
      // 数据总数
      dataTotal: 0,
      BASE_API: getBaseURL('/auth/logistics/download/yunda'), //获取后端接口地址
    }
  },
  methods: {

    exportDataYunda() {
      // 导出韵达数据，导出excel并下载
      window.location.href = this.BASE_API + '/auth/logistics/download/yunda'

    },

    // 快递助手取消上传
    kdzsCancelUpload() {
      this.dialogKdzs = false
    },
    kdzsSuccessUpload() {
      this.dialogKdzs = false
      this.loadAllUploadFileByPage()
    },

    // 我打取消上传
    wodaCancelUpload() {
      this.dialogWoda = false
    },
    wodaSuccessUpload() {
      this.dialogWoda = false
      this.loadAllUploadFileByPage()
    },

    // 聚水潭取消上传
    jstCancelUpload() {
      this.dialogJst = false
    },

    jstSuccessUpload() {
      this.dialogJst = false
      this.loadAllUploadFileByPage()
    },
    // 快麦取消上传
    kmCancelUpload() {
      this.dialogKm = false
    },

    kmSuccessUpload() {
      this.dialogKm = false
      this.loadAllUploadFileByPage()
    },
    oneCancelUpload() {
      this.dialogOne = false
    },

    oneSuccessUpload() {
      this.dialogOne = false
      this.loadAllUploadFileByPage()
    },

    // 每页显示条数变化时触发
    handleSizeChange(val) {
      this.form.pageSize = val
      // 由于修改了每页显示的条数，应当将页数还原默认值1
      this.form.currentPage = 1
      this.loadAllUploadFileByPage()
    },
    // 页号改变时触发
    handleCurrentChange(val) {
      this.form.currentPage = val
      this.loadAllUploadFileByPage()
    },

    // 添加筛选后的数据查询
    async onSubmit() {
      // 请求数据前，将请求的页数更新为1
      this.form.currentPage = 1
      await this.loadAllUploadFileByPage()
    },

    async loadAllUploadFileByPage() {
      // 开始加载数据
      this.isLoading = true
      // 打单时间范围
      if (this.form && this.form.uploadDateQuery) {
        let uploadDateBegin = this.form.uploadDateQuery[0]
        let uploadDateEnd = this.form.uploadDateQuery[1]
        this.form.uploadDateBegin = uploadDateBegin
        this.form.uploadDateEnd = uploadDateEnd
      }else {
        this.form.uploadDateBegin = ""
        this.form.uploadDateEnd = ""
      }
      // 分页多条件查询快递单号信息
      const {data} = await findAllUploadFileByPage(this.form)
      if (200 === data.code) {
        this.tableData = data.data.records
        this.dataTotal = data.data.total
      }
      this.isLoading = false
    },

  },
  created() {
    this.loadAllUploadFileByPage()
  },
  filters: {
    // 日期过滤器
    dateFormate(date) {
      return dateFormate(date)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
